<template>
  <b-card
    no-body
    class="p-1"
  >
    <b-overlay
      :show="loading"
    >
      <div
        class="demo-spacing-0 d-flex justify-content-between m-1"
        dir="rtl"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="isActive=true"
        >
          <feather-icon
            size="16"
            icon="FilterIcon"
          />
          فیلتر
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-modal.modal-1
          variant="info"
        >
          <feather-icon
            size="17"
            icon="FilePlusIcon"
          />
          افزودن کیف پول
        </b-button>

        <b-modal
          id="modal-1"
          v-model="newWalletModal"
          title="ساخت کیف پول جدید"
          ok-title="ساخت کیف پول "
          cancel-title="لغو"
          centered
          @ok.prevent="addWallet"
        >
          <b-form>
            <b-overlay
              :show="loading"
              rounded="sm"
            >
              <b-form-group label="نوع کیف پول">
                <v-select
                  :key="cryptos.length"
                  v-model="wallet.crypto_id"
                  dir="rtl"
                  :options="coins"
                  label="label"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="role"
                  style="min-width:105px"
                />
              </b-form-group>
              <b-form-group label="نام کیف پول">
                <b-form-input v-model="wallet.name" />
              </b-form-group>
              <b-form-group label="آدرس کیف پول">
                <b-form-input
                  v-model="wallet.address"
                  :state="wallet.address != null"
                />
              </b-form-group>
              <v-select
                :key="cryptos.length"
                v-model="wallet.show_status"
                dir="rtl"
                :options="walletStatus"
                label="label"
                :reduce="val => val.value"
                :clearable="false"
                input-id="role"
                style="min-width:105px"
              />
            </b-overlay>
          </b-form>

        </b-modal>

      </div>

      <div class="relative-over-x">
        <b-table
          ref="refUserListTable"
          :items="cryptos"
          :small="true"
          responsive
          :fields="columns"
          primary-key="id"
          show-empty
          empty-text="اطلاعاتی یافت نشد"
          no-local-sort
          style="white-space: nowrap; min-height : 235px"
          @sort-changed="sort($event)"
        >

          <template #cell(persian_name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.crypto_image"
                  :variant="`light-info`"
                />
              </template>
              <b-link
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.crypto_persian_name }}
              </b-link>
              <small class="text-muted">{{ data.item.crypto_symbol }}</small>
            </b-media>
          </template>

          <template #cell(status)="data">
            <!--      <b-badge-->
            <!--        pill-->
            <!--        :variant="statusHandler[data.item.status].color"-->
            <!--      >-->
            <!--        {{ statusHandler[data.item.status].name }}-->
            <!--      </b-badge>-->
            <div style="max-width: 200px">
              <b-form-select
                v-model="data.item.status"
                :options="options"
                :state="data.item.status !== 0"
                size="sm"
                @change="changeStatus(data.item.id)"
              />
            </div>
          </template>

<!--          <template #cell(action)="{item}">-->
<!--            <b-dropdown-->
<!--              id="dropdown-offset"-->
<!--              variant="link"-->
<!--              no-caret-->
<!--              offset="80px"-->
<!--              :right="true"-->
<!--            >-->
<!--              <template #button-content>-->
<!--                <feather-icon-->
<!--                  icon="MoreVerticalIcon"-->
<!--                  size="16"-->
<!--                  class="align-middle text-body"-->
<!--                />-->
<!--              </template>-->

<!--              <b-dropdown-item-->
<!--                @click="orderDetails(item.id)"-->
<!--              >-->
<!--                <feather-icon icon="FileTextIcon" />-->
<!--                <span class="align-middle ml-50">ویرایش</span>-->
<!--              </b-dropdown-item>-->
<!--            </b-dropdown>-->
<!--          </template>-->

        </b-table>

      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BOverlay,
  BTable,
  BDropdown,
  BDropdownItem,
  BMedia,
  BAvatar,
  BLink,
  BFormGroup,
  BForm,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'CryptoWalletAddresses',
  components: {
    BCard,
    BButton,
    BOverlay,
    BTable,
    BDropdown,
    BDropdownItem,
    BMedia,
    BAvatar,
    BLink,
    BFormGroup,
    BForm,
    BFormInput,
    vSelect,
    BFormSelect,
  },
  data() {
    return {
      loading: false,
      isActive: false,
      cryptoWalletAddress: [],
      cryptos: [],
      coins: [],
      newWalletModal: false,
      options: [
        { value: 0, text: 'غیر فعال' },
        { value: 1, text: 'فعال' },
      ],
      wallet: {
        crypto_id: null,
        name: null,
        address: null,
        show_status: 1,
      },
      walletStatus: [{
        label: 'فعال',
        value: 1,
      },
      {
        label: 'غیر فعال',
        value: 0,
      }],
      selected: null,
      columns: [
        {
          label: 'رمز ارز',
          key: 'persian_name',
          sortable: true,
          searchType: 'text',
        },
        {
          label: 'نام کیف پول',
          key: 'name',
          sortable: false,
          searchType: 'text',
        },
        {
          label: 'آدرس کیف پول',
          key: 'address',
          sortable: false,
          searchType: 'text',
        },
        {
          label: 'وضعیت',
          key: 'status',
          sortable: true,
          searchType: 'select',
          selectOptions: [
            { label: 'فعال', value: '1 || true' },
            { label: 'غیر فعال', value: '0 || false' },
          ],
        },
        {
          label: 'تاریخ ایجاد',
          key: 'jalali_created_at',
          sortable: true,
          searchType: 'date',
        },
        {
          label: 'عملیات',
          key: 'action',
        },
      ],
    }
  },
  mounted() {
    this.getCryptoWalletAddress()
    this.getCryptos()
  },
  methods: {
    async getCryptoWalletAddress() {
      this.loading = true
      const response = await this.$axios('/crypto-wallet-address')
      this.cryptos = response.data.data
      this.loading = false
    },
    async getCryptos() {
      this.loading = true
      const array = []
      const response = await this.$axios('/crypto?type=crypto')
      response.data.map(item => {
        const test = { value: item.id, label: item.persian_name }
        array.push(test)
      })
      this.coins = array
      this.loading = false
    },
    async addWallet() {
      const data = this.wallet
      this.loading = true
      const response = await this.$axios({
        url: '/crypto-wallet-address',
        method: 'POST',
        data,
      })
      console.log(response)
      this.loading = true
      this.$swal({
        icon: 'success',
        title: response.data.message,
        confirmButtonText: 'تایید',
        // text: 'Your file has been deleted.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      }).then(() => {
        this.newWalletModal = false
      })
    },
    async changeStatus(id) {
      this.loading = true
      const response = await this.$axios(
        `crypto/${id}/change-status`,
      )
      console.log(response.data)
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
